import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpServiceda } from 'src/app/core/http.service-da';
import { Application } from 'src/app/enum/application.enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {
  private urlParams: string;
  constructor(  private readonly httpServiceda: HttpServiceda,
    private readonly commonService: CommonService,private http:HttpClient) { }
    baseURL: string = environment.baseApiURLprod;
   tokenObj = this.commonService.getLocalStorageItem(Application.TOKEN);

 httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'responseType':'blob',
        'observe' :'body',
      }),
      'responseType': 'text'

    };
  formOptions = {
      headers: new HttpHeaders()
    };
  /**
   * @description call api for getting owner listing data
   * @params params
   * @returns owner listing object
   */
   public getStoreManagement(params) {
    this.urlParams = this.commonService.setUrlParams(params);
    const url = `${Application.STORE_MANAGEMENT}${this.urlParams}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  }
  public delete(id: any) {
    const url = `${Application.STORE}${id}`;
    return this.httpServiceda.httpRequest('DELETE', url, true);
}

updateStore(id,data){
  const url = `${Application.STORE}${id}`;
  return this.httpServiceda.httpRequest('PUT', url,  false, data);
}

UserData(id) {
  const url = `${Application.USER}/${id}`;
  return this.httpServiceda.httpRequest('GET', url, false);
}

kindi(id) {
  const url = `${Application.KINDI}${id}`;
  return this.httpServiceda.httpRequest('PUT', url,  false);
}

public getfragranceData(id: any,params){

  this.urlParams = this.commonService.setUrlParams(params);
const url = `${Application.STORE}/${id}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);

}
public changePassword(email,old,newpss){
  const url = `${Application.CHANGE_PASSWORD}?email=${email}&old_password=${old}&new_password=${newpss}`;
    return this.httpServiceda.httpRequest('GET', url,  true);

  }
  public creatuser(data){
    const url = `${Application.CREAET_ACC_USER}`
    return this.httpServiceda.httpRequest('POST', url, true, data);
  }
  public deleteaccountuser(id,data){
    const url = `${Application.USER}${id}`
    return this.httpServiceda.httpRequest('DELETE', url, true, data);
  }
  public addStore(data){
    const url = `${Application.ADD_STORE}`;
    return this.httpServiceda.httpRequest('POST', url, true,data);
  }
  public chatwidght(data){
    const url = `${Application.CHAT}`;
    return this.httpServiceda.httpRequest('POST', url, true,data);
  }

  public addToFavorites(user_id: string, data) {
    const url = `${Application.WISHLIST}${user_id}`;
    return this.httpServiceda.httpRequest('POST', url, true, data);
  }

  public delete_product_user_list(user_id: string, data) {
    const url = `${Application.PRODUCTS_USER}${user_id}/delete_product`;
    return this.httpServiceda.httpRequest('POST', url, true, data);
  }
  
  public getUserFavorites(user_id: string) {
    const url = `${Application.WISHLIST}${user_id}`; 
    return this.httpServiceda.httpRequest('GET', url, true);
}

  public RemoveFavorites(user_id: string, product_id:string) {
    const url = `${Application.WISHLIST}${user_id}/${product_id}`;
    return this.httpServiceda.httpRequest('DELETE', url, true);
  }

  public otherprice(prodact_id: string, page_num: number, page_size: number, sorting: string = 'value', sort_dir: string = 'desc') {
    const url = `${Application.OTHER_PRICES.replace('{prodact_id}', prodact_id)}?page_num=${page_num}&page_size=${page_size}&sorting=${sorting}&sort_dir=${sort_dir}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  }
  public pricesRec(prodact_id: string, page_num: number, page_size: number, sorting: string = 'value', sort_dir: string = 'desc') {
    const url = `${Application.OTHER_PRICES.replace('{prodact_id}', prodact_id)}?page_num=${page_num}&page_size=${page_size}&sorting=${sorting}&sort_dir=${sort_dir}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  }

  public partner(data){
    const url = `${Application.PARTNER}`;
    return this.httpServiceda.httpRequest('POST', url, true,data);
  }
  public subscribe(data){
    const url = `${Application.EMAIL}`;
    return this.httpServiceda.httpRequest('POST', url, true,data);
  }
  public perfumeMangement(params){
    this.urlParams = this.commonService.setUrlParams(params);
    const url = `${Application.PRODUCT_LIST}${this.urlParams}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  }
  public perfumeAffiliateMangement(params){
    this.urlParams = this.commonService.setUrlParams(params);
    const url = `${Application.PERFUME_AFFILIATE_MANAGEMENT}${this.urlParams}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  }


  public productfilter(name) {
    const encodedName = encodeURIComponent(name); 
    const url = `${Application.PERFUME_AFFILIATE}/?name=${encodedName}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  }

  public perfumlist(){
     const url = `${Application.PERFUME_LIST}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  }

  public delete_product(id){
    const url = `${Application.PRODUCT}${id}`;
    return this.httpServiceda.httpRequest('DELETE', url,  false);
  }
  public getperfumlist(id){
    const url = `${Application.PERFUME_LIST}${id}`;
   return this.httpServiceda.httpRequest('GET', url, true);
 }

 public addprice(id,data){
  const url = `${Application.ADD_PEICE}${id}`;
 return this.httpServiceda.httpRequest('POST', url, true,data);
}
public addadmin(data){
  const url = `${Application.ADD_ADMIN}`;
 return this.httpServiceda.httpRequest('POST', url, true,data);
}

public removedata(id){
  const url = `${Application.REMOVE_DATA_CLIENT}${id}`;
 return this.httpServiceda.httpRequest('DELETE', url, true);
}

addpricebyid(product_id: string, price_product_id: string) {
  const url = `${Application.ADD_PEICE_BY_ID}/${product_id}/${price_product_id}`;
  return this.httpServiceda.httpRequest('POST', url, true, {});
}
removeprice(id) {
  const url = `${Application.PRICE_DELETE}/${id}`;
  return this.httpServiceda.httpRequest('DELETE', url, true, {});
}

public EditStoreperfume(data,id){
  const url = `${Application.PRODUCT_STORE_DATA}${id}`;
 return this.httpServiceda.httpRequest('POST', url, true,data);
}
public EditClient(data){
  const url = `${Application.CLIENT}`;
 return this.httpServiceda.httpRequest('POST', url, true,data);
}

public Editdashboard(data,id){
  const url = `${Application.EDIT_DASHBARAD}${id}`;
 return this.httpServiceda.httpRequest('POST', url, true,data);
}


public getEditPerfume( id){
  const url = `${Application.PRODUCT}${id}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}

public getEditPerfumeStore( id,store){
  const url = `${Application.PRODUCT_DELETE_STORE}${id}/product/url/${store}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}

public getdataclient(id){
  const url = `${Application.GET_CLIENT}${id}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}

public EditPerfume(id,data){
 const url = `${Application.PRODUCT}${id}`;
 return this.httpServiceda.httpRequest('PUT', url, true,data);
}
// this for client list
public changestatus( id,data){
 const url = `${Application.CLIENT_STATUS}${id}`;
 return this.httpServiceda.httpRequest('PUT', url, true, data);
}

// this for price in prodact
 changestatusdata(product_id: string, price_product_id: string,data) {
  const url = `${Application.PRICE_STATUS_DATA}/${product_id}/${price_product_id}`;
  return this.httpServiceda.httpRequest('PUT', url, true, data);
}

public userFeedback(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.USER_FEEDBACK}${this.urlParams}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}
public ChatDashbord(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.CHAT_LIST}${this.urlParams}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}

public  resetPassword(data){
  const url = `${Application.RESET_PASSWORD}`;
 return this.httpServiceda.httpRequest('POST', url, true,data);
}

public  forgetPassword(data: any) {
  const url = Application.FORGET_PASSWORD;
  return this.httpServiceda.httpRequest(Application.POST, url, true, data);
}

public listclient(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.LIST_CLIECNT}${this.urlParams}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}



public webhook(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.WEEBHOOK_SALLA}${this.urlParams}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}
public SubDashbord(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.SUB_DASHBOARD}${this.urlParams}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}
public PartnerDashbord(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.PARTNER_DASHBOARD}${this.urlParams}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}
public deletepartner(id){
  const url = `${Application.PARTNER}${id}`;
  return this.httpServiceda.httpRequest('DELETE', url,  false);
}
public deletechat(id){
  const url = `${Application.CHAT}${id}`;
  return this.httpServiceda.httpRequest('DELETE', url,  false);
}
public deleteclient(id){
  const url = `${Application.CLIENT_DELETE}${id}`;
  return this.httpServiceda.httpRequest('DELETE', url, false);
}
public actionuser(Data) {
  const url = `${Application.TRIGGER}`;
  return this.httpServiceda.httpRequest('POST', url,true,  Data);
}


public deleteEmail(id){
  const url = `${Application.EMAIL}${id}`;
  return this.httpServiceda.httpRequest('DELETE', url,  false);
}


deletPrice(price_product_id: string) {
  const url = `${Application.PRICE_DELETE}/${price_product_id}`;
  return this.httpServiceda.httpRequest('DELETE', url, false, {});
}

public deleteInvite(id){
  const url = `${Application.UPDATE_INVITE}${id}`;
  return this.httpServiceda.httpRequest('DELETE', url,  false);

}

public userManagement(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.USER_MANAGEMENT}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}
public gutesManagement(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.GUEST_MANAGEMENT}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}

public userDetails(id){
  const url = `${Application.USER_DETAIL}${id}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}

public userEventDetails(id){
  const url = `${Application.ADD_EVENT}${id}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}
public addEvent(data){
  const url = `${Application.ADD_EVENT}`;
  return this.httpServiceda.httpRequest('POST', url,  true, data);
}

public gutesDetails(id){
  const url = `${Application.TRIGGER_DETALIS }${id}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}
public suggeted(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.SUGGESTED}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}productDetails
public (id){
  const url = `${Application.PRODUCT}${id}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}
public product_details(id){
  const url = `${Application.PRODUCT_DETAILS}${id}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}
public purcahsePerfume(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.REPORT_MANAGEMENT}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}

public purchasePerfume(id){
  const url = `${Application.REPORT_MANAGEMENT}${id}`;
  return this.httpServiceda.httpRequest('GET', url,  true);
}
public filter(search?){
  let url
  if(search){
    url = `${Application.USER_DETAIL_FILTER}&search=${search}`;
  }
 else{
    url = `${Application.USER_DETAIL_FILTER}`;
 }
  return this.httpServiceda.httpRequest('GET', url,  true);
}


updateUserManagement(id,data){
  const url = `${Application.USER_DETAIL}${id}`;
  return this.httpServiceda.httpRequest('PUT', url,  false, data);
}

public downloadReportStore(params) {
  let store
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.DOWNLOAD_REPORT_Store}${this.urlParams}`;
   const passurl =`${this.baseURL}${url}`
    store = this.http.get(`${this.baseURL}${url}`, {
    headers: new HttpHeaders({
      'Authorization':  `Bearer ${String(this.tokenObj)}`,
      'Content-Type': 'application/xml'
    }),
    responseType: 'blob' ,
  });
  return store;

}
public downloadReportPerfume(params):any {
  let perfume
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.DOWNLOAD_REPORT_PERFUME}${this.urlParams }`;
  perfume = this.http.get(`${this.baseURL}${url}`, {
    headers: new HttpHeaders({
      'Authorization':  `Bearer ${String(this.tokenObj)}`,
      'Content-Type': 'application/xml'
    }),
    responseType: 'blob' ,
  });
  return perfume;
}
public downloadReportMangement(params) {
  let reportMangement
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.REPORT_MANAGEMENT_Download}${this.urlParams}`;
   reportMangement = this.http.get(`${this.baseURL}${url}`, {
    headers: new HttpHeaders({
      'Authorization':  `Bearer ${String(this.tokenObj)}`,
      'Content-Type': 'application/xml'
    }),
    responseType: 'blob' ,
  });
  return reportMangement;
}
public downloadReportSubscribe(params) {
  let reportMangement
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.REPORT_MANAGEMENT_Subscribe}${this.urlParams}`;
   reportMangement = this.http.get(`${this.baseURL}${url}`, {
    headers: new HttpHeaders({
      'Authorization':  `Bearer ${String(this.tokenObj)}`,
      'Content-Type': 'application/xml'
    }),
    responseType: 'blob' ,
  });
  return reportMangement;
}

public loginuser(data: any) {
  const url = Application.LOGIN;
  return this.httpServiceda.httpRequest(Application.POST, url, true, data);
}


updateUserData(id, data) {
  const url = `${Application.USER}${id}`;
  return this.httpServiceda.httpRequest('PUT', url, false, data);
}
addclient(data) {
  const url = `${Application.CLIENT}`;
  return this.httpServiceda.httpRequest('POST', url, true, data);
}

public updatePerfume(id,data){
  const url = `${Application.PRODUCT_STOCK_STATUS}${id}`;
  return this.httpServiceda.httpRequest('PUT', url,  false, data);
}
public updateStorePerfume(id,data,storeId){
  const url = `${Application.STORE}${storeId}/${Application.PRODUCT}${id}`;
  return this.httpServiceda.httpRequest('PUT', url,  false, data);
}
public updateInvite(id,data){
  const url = `${Application.UPDATE_INVITE}${id}`;
  return this.httpServiceda.httpRequest('PUT', url,  false, data);
}
public change_to_not_admin(id){
  const url = `${Application.UPDATE_INVITE_ADMIN}${id}`;
  return this.httpServiceda.httpRequest('PUT', url,  false);
}
public  invitMember(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.INVITE_MEMBER}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}
public addInvitMember(data){
  const url = `${Application.INVITE_MEMBER_Add}`;
 return this.httpServiceda.httpRequest('POST', url, true,data);
}
public getadminMember(){
  const url = `${Application.get_ADMIN}`;
 return this.httpServiceda.httpRequest('GET', url, true);
}
public reportData(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.REPORT_MANAGEMENT}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}

public reportDetails(params,id){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.REPORT_MANAGEMENT_DETAILS}${id}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}
public priceDetails(params,id){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.PRICE_MANAGEMENT_DETAILS}${id}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}
public pricedahsboard(params,id){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.PRICE_MANAGEMENT_DETAILS}${id}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}


public priceDetailsbyid(ids) {
  const requests = ids.map(id => {
    const url = `${Application.PRICE_MANAGEMENT_DETAILS_BYID}${id}`;
    return this.httpServiceda.httpRequest('GET', url, true);
  });
  return forkJoin(requests);
}
public gutesDetailsrec(id) {
    const url = `${Application.PRODUCT}${id}`;
    return this.httpServiceda.httpRequest('GET', url, true);
}
public productsDetails(data) {
  const url = `${Application.PRODUCTS_DETAILS}?product_ids=${data}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}
public priceDetailsbyid_edit(id) {
    const url = `${Application.PRICE_MANAGEMENT_DETAILS_BYID}${id}`;
    return this.httpServiceda.httpRequest('GET', url, true);

}

public ingredientDetails(data) {
  const url = `${Application.INGREDIENT_PRODUCTS_LIST}?ingredient_ids=${data}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}


public dataperfume(id) {
  const url = `${Application.PRODUCT}${id}`;
  return this.httpServiceda.httpRequest('GET', url, true);

}
public ingredient(data) {
  const url = `${Application.INGREDIENT}${data}`;
  return this.httpServiceda.httpRequest('GET', url, true);

}
public ingredient_list(id) {
  const url = `${Application.PRODUCT_LIST}${id}`;
  return this.httpServiceda.httpRequest('GET', url, true);

}
public getuserlist(id) {
  const url = `${Application.PRODUCTS_USER}${id}`;
  return this.httpServiceda.httpRequest('GET', url, true);

}

public dataDetails(params,id){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.DATA_MANAGEMENT_DETAILS}${id}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}
public purcahseReportData(params){
  this.urlParams = this.commonService.setUrlParams(params);
  const url = `${Application.REPORT_MANAGEMENT}${this.urlParams}`;
  return this.httpServiceda.httpRequest('GET', url, true);
}

public suggestedperfume(data){
  const url = `${Application.SUGGESTEDPERFUME}`
  return this.httpServiceda.httpRequest('POST', url, true, data);
}

public dashboardPerfumeapi(report_type,store?){
  let url
  if(store){
    url = `${Application.DASHBOARD_PRODUCT}=${report_type}&store=${store}`;
}
else{
  url = `${Application.DASHBOARD_PRODUCT}=${report_type}`;
}

   return this.httpServiceda.httpRequest('GET', url, true);
}

public dashboardStoreapi(report_type,store?){
   let url
  if(store){
    url = `${Application.DASHBOARD_STORE}=${report_type}&store=${store}`;
}
else{
  url = `${Application.DASHBOARD_STORE}=${report_type}`;
}
  return this.httpServiceda.httpRequest('GET', url, true);
}
public dashboarddonaapi(report_type,store?){
  let url
 if(store){
   url = `${Application.DASHBOARD_PRICES}=${report_type}&store=${store}`;
}
else{
 url = `${Application.DASHBOARD_PRICES}=${report_type}`;
}
 return this.httpServiceda.httpRequest('GET', url, true);
}


public dashboardVisitoreapi(report_type,store?){
    let url
  if(store){
    url = `${Application.DASHBOARD_VISITORE}=${report_type}&store=${store}`;
}
else{
  url = `${Application.DASHBOARD_VISITORE}=${report_type}`;
}

   return this.httpServiceda.httpRequest('GET', url, true);
}
public dashboardSaleapi(report_type,store?){
   let url
  if(store){
    url = `${Application.DASHBOARD_REVENUE}=${report_type}&store=${store}`;
}
else{
  url = `${Application.DASHBOARD_REVENUE}=${report_type}`;
}
  return this.httpServiceda.httpRequest('GET', url, true);
}

public dashboardRecommendedApi(report_type,store?){

  let url
  if(store){
    url = `${Application.DASHBOARD_RECOMMENDED}=${report_type}&store=${store}`;
}
else{
  url = `${Application.DASHBOARD_RECOMMENDED}=${report_type}`;
}
  return this.httpServiceda.httpRequest('GET', url, true);
}

public dashboardBoughtApi(report_type,store?){
  let url
  if(store){
    url = `${Application.DASHBOARD_BOUGHT}=${report_type}&store=${store}`;
}
else{
  url = `${Application.DASHBOARD_BOUGHT}=${report_type}`;
}

  return this.httpServiceda.httpRequest('GET', url, true);
}
download(url, downloadName) {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = downloadName;
  a.click();
  document.body.removeChild(a);
}

}
