export enum Application {
  TOKEN = 'access_token',
  Email = 'email',
  created_date = "created_date",
  CRYPTO_PASSWORD = "CRYPTO_PASSWORD",
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  DOWNLOAD = 'DOWNLOAD',

  // Router
  CREAET_ACC_USER = 'dona/user/signup',
  LOGIN_DASHBORD = 'login/access-token',
  LOGIN = 'login/access-token',
  // LOGIN = 'login/access-token?role=dona',
  USER = 'dona/user',
  CHANGE_PASSWORD = 'user/changepassword?role=dona',
  RESET_PASSWORD = 'user/resetpassword?role=dona',
  FORGET_PASSWORD = 'user/forgotpassword?app=dona',

  RESET_USER_PASSWORD = 'dona/user/resetpassword',
  FORGET_USER_PASSWORD = 'dona/user/forgotpassword',

  USER_DETAIL = 'dona/user/trigger/',
  ADD_ADMIN = 'user/add?role=admin',
  USER_MANAGEMENT = 'dona/users/list',
  PRODUCTS_USER = 'dona/user/products/',
  PRODUCTS_DELLETE = 'dona/user/products/',

  CHAT = 'chat/',
  CHAT_LIST = 'chat/list',

  PRODUCT_STORE_DATA = 'store/product/',
  STORE = 'store/',
  PRODUCT_DELETE_STORE = 'store/',
  STORE_validate = 'store/validate',
  DOWNLOAD_REPORT_Store = 'store/store/export-report',

  PRODUCT_LIST = 'product/list',
  PRODUCT = 'product/',
  OTHER_PRICES = `product/prices/{prodact_id}`,
  PERFUME_LIST = 'product/list?page_num=1&page_size=1000&limit=100',
  ADD_PEICE = 'product/add_price/',
  ADD_PEICE_BY_ID = 'product/add_price_client',
  PRODUCT_STOCK_STATUS = 'product/stock/',
  PRICE_DELETE = 'product/remove_price',
  PRICE_STATUS_DATA = 'product/update_stock',
  PRICE_MANAGEMENT_DETAILS = 'product/prices/',
  PRODUCTS_DETAILS = 'product/list/products/',
  SEARCH_PERFUME = 'product/searched_perfume/',
  DOWNLOAD_REPORT_PERFUME = 'product/product/export-report',

  WISHLIST = `wishlist/`,

  ADD_EVENT = 'events/',
  TRIGGER = 'events/trigger',
  TRIGGER_DETALIS = 'events/trigger/',
  GUEST_MANAGEMENT = 'events/Trigger/guest',

  PARTNER = 'partner/',
  PARTNER_DASHBOARD = 'partner/list',

  EMAIL = 'email/',
  SUB_DASHBOARD = 'email/list',

  PERFUME_AFFILIATE_MANAGEMENT = 'product_website/list',
  PERFUME_AFFILIATE = 'product_website/filtered_products',

  STORE_MANAGEMENT = 'store_website/list',
  ADD_STORE = 'store_website/add',

  PRICE_MANAGEMENT_DETAILS_BYID = 'sites/product/',
  KINDI = 'sites/update_products_status/',
  EDIT_DASHBARAD = 'sites/data/',
  CLIENT = 'sites/add_or_update',
  DATA_MANAGEMENT_DETAILS = 'sites/products/',
  PRODUCT_DETAILS = 'sites/product_details/',
  LIST_CLIECNT = 'sites/get_sites_list',
  CLIENT_DELETE = 'sites/',
  GET_CLIENT = 'sites/get/',
  CLIENT_STATUS = 'sites/stock/',
  REMOVE_DATA_CLIENT = 'sites/remove_prices_for_client/',
  REPORT_MANAGEMENT_Download = 'sites/partner/export-report',
  REPORT_MANAGEMENT_Subscribe = 'sites/Subscribe/export-report',

  INGREDIENT = 'ingredient/',
  INGREDIENT_PRODUCTS_LIST = 'ingredient/list/ingredient/',

  get_ADMIN = 'user/admin/all',
  UPDATE_INVITE_ADMIN = 'dona/change_to_not_admin/',
  COMPARE = 'dona/compare/',

  INVITE_MEMBER = 'invite/list',
  INVITE_MEMBER_Add = 'invite/add',
  UPDATE_INVITE = 'invite/',

  REPORT_MANAGEMENT = 'report/list',
  REPORT_MANAGEMENT_DETAILS = 'report/detail/',

  favorite = 'fav/add',

  WEEBHOOK_SALLA = 'salla/get_weebhok_list',

  FEEDBACK = 'feedback/add',
  USER_FEEDBACK = 'feedback/list',

  SUGGESTEDPERFUME = 'customer/suggested/perfume',
  SignIn = 'customer/signin?',
  SUGGESTED = 'customer/dona/suggested/list',
  USER_DETAIL_FILTER = 'customer/filter?limit=10000&page_size=100',
  USER_MANAGEMENT_UPDATE = 'customer/',
  CUSTOMER_SIGN_IN = 'customer/signin',

  RECOMMENDATION = 'recommend/widget/suggestion',

  DASHBOARD_PRICES = 'dashboard/dona/perfumes/report?report_type',
  DASHBOARD_PRODUCT = 'dashboard/perfumes/report?report_type',
  DASHBOARD_STORE = 'dashboard/stores/report?report_type',
  DASHBOARD_VISITORE = 'dashboard/visitors/report?report_type',
  DASHBOARD_REVENUE = 'dashboard/storesales/report?report_type',
  DASHBOARD_RECOMMENDED = 'dashboard/recommended/perfume/report?report_type',
  DASHBOARD_BOUGHT = 'dashboard/bought/report?report_type',

  // Number
  TAB = -1,
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  TWELVE = 12,
  THIRTEEN = 13,
  TWENTY = 20,
  TWENTY_FOUR = 24,
  THIRTY_TWO = 32,
  FOURTY_EIGHT = 48,
  FIFTY = 50,
  FIFTY_SEVEN = 57,
  SIXTY = 60,
  HUNDRED = 100,
  HUNDRED_ONE = 101,
  ONE_HUNDRED_FIFTEEN = 115,
  ONE_HUNDRED_EIGHTY = 180,
  TWO_FIVE_FIVE = 255,
  THREE_HUNDRED = 300,
  FIVE_HUNDRED = 500,
  FIVE_HUNDRED_TWO = 502,
  FOUR_HUNDRED_ONE = 401,
  FOUR_HUNDRED = 400,
  FOUR_HUNDRED_THREE = 403,
  FOUR_HUNDRED_FOUR = 404,
  TEN_THOUSAND = 10000,
  PAGE_LIMIT = 10,
}